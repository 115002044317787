import camelCaseKeys from 'camelcase-keys'
import snakeCaseKeys from 'snakecase-keys'

export type UnknownJson = Parameters<typeof camelCaseKeys>[0]

const startingWithDoubleUnderscore = /^__\w+/
const includingDot = /\./

export const parseToCamelCaseKeys = (input: UnknownJson) =>
  camelCaseKeys(input, {
    deep: true,
    exclude: [startingWithDoubleUnderscore, includingDot],
  })

export const parseToSnakeCaseKeys = (input: UnknownJson) =>
  snakeCaseKeys(input, {
    deep: true,
    exclude: [startingWithDoubleUnderscore, includingDot],
  })
