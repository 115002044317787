import { stringify } from 'qs'
import { useCurrentCompany } from '@/modules/base/composable/useCurrentCompany'
import { parseToSnakeCaseKeys } from '@/modules/base/utils/json'
import { Endpoint, apiPrefix } from '../utils/url'

export type QueryParams = {
  [key: string]: QueryParams | string[] | string | null | undefined
}

export const createUrl = (
  pathElements: readonly unknown[],
  queryParams: QueryParams = {}
) => {
  try {
    const baseUrl = window.location.origin
    const url = new URL([apiPrefix, ...pathElements].join('/'), baseUrl)

    url.search = stringify(parseToSnakeCaseKeys(queryParams), {
      arrayFormat: 'brackets',
    })

    return url.toString()
  } catch (e) {
    console.error(e)
  }

  throw new Error(
    `Failed to create URL with path elements: ${pathElements} and query params: ${queryParams}`
  )
}

export const useUrl = () => {
  const { currentCompanyId } = useCurrentCompany()

  const withCompanyId = (url: readonly unknown[]): readonly unknown[] => [
    Endpoint.Companies,
    currentCompanyId,
    ...url,
  ]

  const createUrlWithCompanyId = (...url: unknown[]) =>
    createUrl(withCompanyId(url))

  return {
    createUrl,
    withCompanyId,
    createUrlWithCompanyId,
  }
}
